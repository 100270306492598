export const Nav20DataSource = {
  isScrollLink: true,
  wrapper: { className: 'header2 home-page-wrapper ke3q00azjug-editor_css' },
  page: { className: 'home-page' },
  logo: {
    className: 'header2-logo',
    children: '/images/logo.png',
  },
  LinkMenu: {
    className: 'header2-menu',
    children: [
      {
        name: 'linkNav',
        to: 'product',
        children: '辣创产品系列',
        className: 'menu-item',
      },
      {
        name: 'linkNav~ker3sq5c5h',
        to: 'business',
        children: '主营业务',
        className: 'menu-item',
      },
      {
        name: 'linkNav~ker3sq5c5h2',
        to: 'about',
        children: '关于我们',
        className: 'menu-item',
      },
      {
        name: 'linkNav~ker3sq5c5h3',
        to: 'contact',
        children: '联系我们',
        className: 'menu-item',
      },
    ],
  },
  mobileMenu: { className: 'header2-mobile-menu' },
};
export const Banner20DataSource = {
  wrapper: { className: 'banner2' },
  BannerAnim: {
    children: [
      {
        name: 'elem0',
        BannerElement: { className: 'banner-user-elem' },
        page: { className: 'home-page banner2-page' },
        textWrapper: { className: 'banner2-text-wrapper' },
        bg: { className: 'bg bg0' },
        title: {
          className: 'banner2-title ke5a224zlzc-editor_css',
          children: '/images/home-title.png',
        },
        content: {
          className: 'banner2-content',
          children: '/images/home-desc.jpg',
        },
      },
      {
        name: 'elem2',
        BannerElement: { className: 'banner-user-elem' },
        page: { className: 'home-page banner2-page' },
        textWrapper: { className: 'banner2-text-wrapper' },
        bg: { className: 'bg bg2' },
        title: {
          className: 'banner2-title ke5a224zlzc-editor_css',
          children: '/images/home-title.png',
        },
        content: {
          className: 'banner2-content',
          children: '/images/home-desc.jpg',
        },
      },
    ],
  },
  titleBg: {
    className: 'title-bg',
    background: '/images/bg-hover.png',
    src: '/images/lachuan.png',
  },
  mainText: {
    className: 'main-text',
    children: [
      {
        className: 'text-normal',
        content: '为火锅连锁餐饮提供',
      },
      {
        className: 'text-spatical',
        content: '一站式 标准化 定制化',
      },
      {
        className: 'text-normal',
        content: '口味研发及定制解决方案',
      },
    ],
  },
};
export const Content30DataSource = {
  wrapper: {
    className: 'home-page-wrapper content3-wrapper ke3q35sjyt-editor_css',
  },
  page: { className: 'home-page content3' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [],
  },
  block: {
    className: 'content3-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content3-block content3-block-hover',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children: '/images/prod-fish.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '火锅鱼调味料' },
          desc: {
            className: 'content3-desc',
            children: '美蛙鱼头、椒麻鱼、酸菜鱼、野山椒、苗家酸菜鱼逾10种',
          },
          content: {
            className: 'content3-content',
            children: [],
          },
        },
      },
      {
        name: 'block1',
        className: 'content3-block content3-block-hover',
        md: 4,
        xs: 12,
        children: {
          icon: {
            className: 'content3-icon',
            children: '/images/prod-jcms.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '简餐面食调味酱' },
          desc: {
            className: 'content3-desc',
            children: '小面、米线、酸辣粉、毛血旺、鸡公煲、酸菜、冷锅串串逾30种',
          },
          content: {
            className: 'content3-content',
            children: [],
          },
        },
      },
      {
        name: 'block2',
        className: 'content3-block content3-block-hover',
        md: 4,
        xs: 12,
        children: {
          icon: {
            className: 'content3-icon',
            children: '/images/prod-gftw.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '干粉调味料' },
          desc: {
            className: 'content3-desc',
            children: '火锅增鲜、干油碟、孜香、麻辣、烧烤、荔枝烤肉、腌鱼等',
          },
          content: {
            className: 'content3-content',
            children: [],
          },
        },
      },
      {
        name: 'block3',
        className: 'content3-block content3-block-hover',
        style: { marginTop: '-4px' },
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children: '/images/prod-hotpot.jpg',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '经典火锅料' },
          desc: {
            className: 'content3-desc',
            children: '辣创品牌重庆火锅料、经典火锅料、牛油火锅、清油火锅逾10种',
          },
          content: {
            className: 'content3-content',
            children: [],
          },
        },
      },
      {
        name: 'block4',
        className: 'content3-block content3-block-hover',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children: '/images/prod-fwtd.jpg',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '风味汤底调味料' },
          desc: {
            className: 'content3-desc',
            children: '番茄牛尾、冬阴功、菌汤、猪骨浓汤、鸡汤、三鲜汤逾10种',
          },
          content: {
            className: 'content3-content',
            children: [],
          },
        },
        style: { marginTop: -185 },
      },
      {
        name: 'block5',
        className: 'content3-block',
        md: 8,
        xs: 24,
        style: { padding: '10px', marginTop: -190 },
        children: {
          icon: {
            className: 'content3-icon',
            children: '/images/prod-logo.jpg',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '' },
          // desc: { className: 'content3-desc', children: '知名品牌首席研发官，倾力打造产品，欢迎您的咨询' },
          content: {
            className: 'content3-content',
            children: [],
          },
        },
      },
      {
        name: 'block6',
        className: 'content3-block content3-block-hover',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children: '/images/prod-zstw.jpg',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '中式特色菜底料' },
          desc: {
            className: 'content3-desc',
            children: '纸包鱼、泡椒烤鱼、香辣烤鱼、蒜蓉烤鱼、小龙虾调料逾20种',
          },
          content: {
            className: 'content3-content',
            children: [],
          },
        },
      },
      {
        name: 'block7',
        md: 8,
        xs: 24,
        children: {
          icon: {},
          title: { className: '', children: '' },
        },
      },
      {
        name: 'block8',
        className: 'content3-block content3-block-hover',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children: '/images/prod-mltw.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '麻辣调味料' },
          desc: {
            className: 'content3-desc',
            children: '剁椒、牛肉、香菇、XO、孜然、藤椒、菌菇、沙茶等',
          },
          content: {
            className: 'content3-content',
            children: [],
          },
        },
        style: { marginTop: -194 },
      },
    ],
  },
};
export const Content40DataSource = {
  wrapper: {
    className: 'home-page-wrapper content4-wrapper ke3q35sjyt-editor_css',
  },
  page: { className: 'home-page content4' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: '主营业务',
        className: 'title-h1',
      },
    ],
  },
  block: {
    className: 'content4-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content4-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content4-icon',
            children: '/images/busi-gxdz.png',
          },
          textWrapper: { className: 'content4-text' },
          link: { className: 'content4-link' },
          title: { className: 'content4-title', children: '辣创产品订购' },
          content: {
            className: 'content4-content',
            children: [
              {
                title: '产品试样',
                images: '/images/icons/小样.png',
              },
              {
                title: '',
                className: 'arrow',
                images: '/images/icons/箭头.png',
              },
              {
                title: '客户确认',
                images: '/images/icons/确认合同.png',
              },
              {
                title: '',
                className: 'arrow',
                images: '/images/icons/箭头.png',
              },
              {
                title: '签订合同',
                images: '/images/icons/签订合同.png',
              },
              {
                title: '',
                className: 'arrow',
                images: '/images/icons/箭头.png',
              },
              {
                title: '客户付款',
                images: '/images/icons/付款.png',
              },
              {
                title: '',
                className: 'arrow',
                images: '/images/icons/箭头.png',
              },
              {
                title: '生产',
                images: '/images/icons/生产.png',
              },
              {
                title: '',
                className: 'arrow',
                images: '/images/icons/箭头.png',
              },
              {
                title: '包装',
                images: '/images/icons/包装.png',
              },
              {
                title: '',
                className: 'arrow',
                images: '/images/icons/箭头.png',
              },
              {
                title: '发货',
                images: '/images/icons/发货.png',
              },
            ],
          },
        },
      },
      {
        name: 'block1',
        className: 'content4-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content4-icon',
            children: '/images/busi-pfdjg.png',
          },
          link: { className: 'content4-link' },
          textWrapper: { className: 'content4-text' },
          title: { className: 'content4-title', children: '客户个性定制' },
          content: {
            className: 'content4-content',
            children: [
              {
                title: '需求表单',
                images: '/images/icons/需求表单.png',
              },
              {
                title: '',
                className: 'arrow',
                images: '/images/icons/箭头.png',
              },
              {
                title: '签订保密协议',
                images: '/images/icons/保密.png',
              },
              {
                title: '',
                className: 'arrow',
                images: '/images/icons/箭头.png',
              },
              {
                title: '接收配方',
                images: '/images/icons/配方.png',
              },
              {
                title: '',
                className: 'arrow',
                images: '/images/icons/箭头.png',
              },
              {
                title: '试制小样',
                images: '/images/icons/小样.png',
              },
              {
                title: '',
                className: 'arrow',
                images: '/images/icons/箭头.png',
              },
              {
                title: '客户确认',
                images: '/images/icons/确认合同.png',
              },
              {
                title: '',
                className: 'arrow',
                images: '/images/icons/箭头.png',
              },
              {
                title: '客户付款',
                images: '/images/icons/付款.png',
              },
              {
                title: '',
                className: 'arrow',
                images: '/images/icons/箭头.png',
              },
              {
                title: '订单排期',
                images: '/images/icons/订单排期.png',
              },
              {
                title: '',
                className: 'arrow',
                images: '/images/icons/箭头.png',
              },
              {
                title: '生产',
                images: '/images/icons/生产.png',
              },
              {
                title: '',
                className: 'arrow',
                images: '/images/icons/箭头.png',
              },
              {
                title: '包装',
                images: '/images/icons/包装.png',
              },
              {
                title: '',
                className: 'arrow',
                images: '/images/icons/箭头.png',
              },
              {
                title: '发货',
                images: '/images/icons/发货.png',
              },
            ],
          },
        },
      },
      {
        name: 'block2',
        className: 'content4-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content4-icon',
            children: '/images/busi-bzdz.png',
          },
          link: { className: 'content4-link' },
          textWrapper: { className: 'content4-text' },
          title: { className: 'content4-title', children: '包装设计定制' },
          content: {
            className: 'content4-content',
            children: [
              {
                title: '需求表单',
                images: '/images/icons/需求表单.png',
              },
              {
                title: '',
                className: 'arrow',
                images: '/images/icons/箭头.png',
              },
              {
                title: '签订合同',
                images: '/images/icons/签订合同.png',
              },
              {
                title: '',
                className: 'arrow',
                images: '/images/icons/箭头.png',
              },
              {
                title: '包装文案设计',
                images: '/images/icons/设计.png',
              },
              {
                title: '',
                className: 'arrow',
                images: '/images/icons/箭头.png',
              },
              {
                title: '输出菲林',
                images: '/images/icons/小样.png',
              },
              {
                title: '',
                className: 'arrow',
                images: '/images/icons/箭头.png',
              },
              {
                title: '客户确认',
                images: '/images/icons/确认合同.png',
              },
              {
                title: '',
                className: 'arrow',
                images: '/images/icons/箭头.png',
              },
              {
                title: '客户付款',
                images: '/images/icons/付款.png',
              },
              {
                title: '',
                className: 'arrow',
                images: '/images/icons/箭头.png',
              },
              {
                title: '生产',
                images: '/images/icons/生产.png',
              },
              {
                title: '',
                className: 'arrow',
                images: '/images/icons/箭头.png',
              },
              {
                title: '包装',
                images: '/images/icons/包装.png',
              },
              {
                title: '',
                className: 'arrow',
                images: '/images/icons/箭头.png',
              },
              {
                title: '发货',
                images: '/images/icons/发货.png',
              },
            ],
          },
        },
      },
    ],
  },
};
export const Banner50DataSource = {
  wrapper: { className: 'home-page-wrapper banner5' },
  page: { className: 'home-page banner5-page' },
  childWrapper: {
    className: 'banner5-title-wrapper',
    children: [
      { name: 'title', children: '核心优势', className: 'banner5-title' },
      {
        name: 'explain',
        className: 'banner5-explain',
        children: '优管理',
      },
      {
        name: 'content',
        className: 'banner5-content',
        children: 'Excellent Management',
      },
    ],
  },
  image: {
    className: 'banner5-image',
    children: '/images/about-0.png',
  },
  text: {
    className: 'banner5-text',
    children: [
      '公司引入业内先进生产管控、工艺流程控制、技术研发、标准化、规范化经营管理理念，确保所有产品在每一个步骤与细节上的安全、可靠。 ',
      '生产车间采购自国内先进设备供应商，生产线实现一体化、半自动化，具有西南地区先进的生产技术及生产能力。',
    ],
  },
};
export const Banner60DataSource = {
  wrapper: { className: 'home-page-wrapper banner6' },
  page: { className: 'home-page banner6-page' },
  childWrapper: {
    className: 'banner6-title-wrapper',
    children: [
      {
        name: 'image',
        className: 'banner6-big-pic',
        children: '/images/about-2.jpg',
      },
    ],
  },
  content: {
    className: 'banner6-content',
    md: 12,
    xs: 24,
    children: [
      {
        image: '/images/about-icon-1.png',
      },
      {
        image: '/images/about-icon-2.png',
      },
      {
        image: '/images/about-icon-3.png',
      },
      {
        image: '/images/about-icon-4.png',
      },
      {
        image: '/images/about-icon-5.png',
      },
      {
        image: '/images/about-icon-6.png',
      },
    ],
  },
  text: {
    className: 'banner6-image',
    children: [
      { name: 'title', children: '关于辣创', className: 'banner6-title' },
      {
        name: 'explain',
        className: 'banner6-explain',
        children: 'ABOUT US',
      },
      {
        name: 'desc',
        className: 'banner6-desc',
        children:
          '重庆辣创味业，位于重庆市江津区德感食品工业园区，总投资1000万元，年生产能力逾5000吨。专注于为火锅锁餐饮企业提供“一站式、标准化、个性化”口味研发及定制解决方案。',
      },
    ],
  },
};
export const Banner70DataSource = {
  wrapper: { className: 'home-page-wrapper banner7' },
  page: { className: 'home-page banner7-page' },
  childWrapper: {
    className: 'banner7-title-wrapper',
    children: [
      {
        name: 'image',
        children: '/images/about-4.png',
        style: { opacity: 0.0501 },
        className: 'banner7-box',
      },
      {
        name: 'image',
        className: 'banner7-bg',
        children: '/images/about-3.png',
      },
    ],
  },
  image: {
    className: 'banner7-image',
    children: '/images/about-0.png',
  },
  text: {
    className: 'banner7-text',
    children: [
      { name: 'title', children: '核心优势', className: 'banner7-title' },
      {
        name: 'explain',
        className: 'banner7-explain',
        children: '优服务',
      },
      {
        name: 'content',
        className: 'banner7-content',
        children: 'Excellent Service',
      },
      {
        name: 'text',
        className: 'banner7-text',
        children:
          '公司严格按照国家食品安全法的标准管理和生产，坚持“专注，创新，安全”的经营理念，深入挖掘不同连锁餐饮企业对于口味定制服务的个性，提供包括：品牌配方代加工服务，底料、酱料、汤料等产品定制服务，新口味研发服务。',
      },
    ],
  },
};
export const AboutDataSource = {
  wrapper: { className: 'home-page-wrapper about' },
  page: { className: 'home-page about-page' },
  childWrapper: {
    className: 'about-title-wrapper',
    children: [
      { name: 'title', children: '核心优势', className: 'about-title' },
      {
        name: 'explain',
        className: 'about-explain',
        children: '优管理',
      },
      {
        name: 'content',
        className: 'about-content',
        children: 'Excellent Management',
      },
    ],
  },
  image: {
    className: 'about-image',
    children: '/images/about-0.png',
  },
  text: {
    className: 'about-text',
    children: [
      {
        className: 'about-text-para',
        children:
          '公司引入业内先进生产管控、工艺流程控制、技术研发、标准化、规范化经营管理理念，确保所有产品在每一个步骤与细节上的安全、可靠。 ',
      },
      {
        className: 'about-text-h',
        children: '核心优势—优管理',
      },
      {
        className: 'about-text-para',
        children:
          '生产车间采购自国内先进设备供应商，生产线实现一体化、半自动化，具有西南地区先进的生产技术及生产能力。',
      },
      {
        className: 'about-text-h',
        children: '核心优势—优服务',
      },
      {
        className: 'about-text-para',
        children:
          '公司严格按照国家食品安全法的标准管理和生产，坚持“专注，创新，安全”的经营理念，深入挖掘不同连锁餐饮企业对于口味定制服务的个性需求，提供包括：品牌配方代加工服务，底料、酱料、汤料等产品定制服务，新口味研发服务。',
      },
    ],
  },
  images: ['/images/about-m-0.jpg', '/images/about-m-1.jpg', '/images/about-m-2.jpg'],
};
export const Teams10DataSource = {
  wrapper: { className: 'home-page-wrapper teams1-wrapper' },
  page: { className: 'home-page teams1' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: '重庆辣创味业' }],
  },
  company: { 
    className: 'comp-wrapper',
    children: [
      { className:'comp', name: 'comp1', children: '重庆辣创调味品有限公司' },
      { className:'comp', name: 'comp2', children: '重庆辣创进出口贸易有限公司' },
      { className:'comp', name: 'comp3', children: '重庆料先生调味品有限公司' },
    ],
  },
  block: {
    className: 'block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children: '/images/contact-0.png',
            },
            { name: 'title', className: 'teams1-title', children: '电话' },
            {
              name: 'content',
              className: 'teams1-text',
              children: '023-85562510',
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children: '/images/contact-1.png',
            },
            { name: 'title', className: 'teams1-title', children: '微信公众号' },
            {
              name: 'content',
              className: 'teams1-text',
              children: '辣创',
            },
            {
              name: 'content0',
              className: 'teams1-text',
              children: 'lachuang-cq',
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children: '/images/contact-2.png',
            },
            { name: 'title', className: 'teams1-title', children: '地址' },
            {
              name: 'content',
              className: 'teams1-text',
              children: '重庆市江津区德感工业',
            },
            {
              name: 'content0',
              className: 'teams1-text',
              children: '园区东江路555号',
            },
            {
              name: 'content1',
              className: 'teams1-content',
              children: '（金桥食品产业园B栋）',
            },
          ],
        },
      },
    ],
  },
};
export const Contact00DataSource = {
  wrapper: { className: 'home-page-wrapper content10-wrapper' },
  Content: {
    className: 'icon-wrapper',
    children: {
      icon: {
        className: 'icon',
        children: 'https://gw.alipayobjects.com/zos/rmsportal/zIUVomgdcKEKcnnQdOzw.svg',
        name: '主要图标',
      },
      iconShadow: {
        className: 'icon-shadow',
        children: 'https://gw.alipayobjects.com/zos/rmsportal/WIePwurYppfVvDNASZRN.svg',
        name: '图标影阴',
      },
      url: { children: 'https://gaode.com/place/B0FFHC4Y17', name: '跳转地址' },
      title: { children: '重庆辣创调味品有限公司', name: '弹框标题' },
      content: {
        children: '重庆市江津区德感工业园区东江路555号（金桥食品产业园B栋）',
        name: '弹框内容',
      },
    },
  },
};
export const Footer20DataSource = {
  wrapper: { className: 'home-page-wrapper footer2-wrapper' },
  OverPack: { className: 'home-page footer2', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: [
      {
        name: 'copyright',
        children: 'Copyright © 2020 重庆料先生调味品有限公司/重庆辣创调味品有限公司',
        className: 'copyright-text',
      }
    ],
  },
  icp: {
    className: 'icp',
    children: [
      {
        name: 'icp',
        children: '渝ICP备18009167号-2',
        href: 'https://beian.miit.gov.cn',
        className: 'icp-link',
      },
    ],
  },
  links: {
    className: 'links',
    children: [
      {
        name: 'gongan-icp',
        children: '渝公网安备 50011602500563号',
        src: '/images/icp.png',
        href: 'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50011602500563',
        className: 'gongan-link',
      },
    ],
  },
};
